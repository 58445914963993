import { BottomNavigationTypeEnum } from '@/graphql/types';
import {
  FaBoxOpen,
  FaChartBar,
  FaCheckCircle,
  FaClipboard,
  FaCog,
  FaRobot,
  FaTasks,
} from 'react-icons/fa';
import { FaRegCalendarDays } from 'react-icons/fa6';
import {
  MdDoneAll,
  MdEditDocument,
  MdFactory,
  MdGroups,
  MdPerson,
  MdQuestionAnswer,
} from 'react-icons/md';
import { LinkItemType } from './type';

// manualをサーバーから削除後にomitする
export const LinkItems: Record<Exclude<BottomNavigationTypeEnum, 'manual'>, LinkItemType> = {
  task: { name: 'task', icon: FaCheckCircle, link: '/' },
  checkList: {
    name: 'check-list',
    icon: FaTasks,
    link: '/check-lists/',
  },
  schedule: {
    name: 'schedule',
    icon: FaRegCalendarDays,
    link: '/schedules/',
  },
  request: { name: 'request', icon: FaClipboard, link: '/requests/' },
  chat: { name: 'chat', icon: MdQuestionAnswer, link: '/chats/' },
  dashboard: {
    name: 'dashboard',
    icon: FaChartBar,
    link: '/dashboard/',
  },
  asset: { name: 'asset', icon: MdFactory, link: '/assets/' },
  part: {
    name: 'parts',
    icon: FaCog,
    link: '/parts/',
  },
  user: {
    name: 'user',
    icon: MdPerson,
    link: '/users/',
  },
  group: {
    name: 'group',
    icon: MdGroups,
    link: '/groups/',
  },
  product: {
    name: 'product',
    icon: FaBoxOpen,
    link: '/products/',
  },
  bot: {
    name: 'ai-bot',
    icon: FaRobot,
    link: '/bots/',
  },
  report: {
    name: 'report',
    icon: MdEditDocument,
    link: '/reports/',
  },
  approval: {
    name: 'approval',
    icon: MdDoneAll,
    link: '/workflows/',
  },
};
