import { Box, Flex, Icon, Text, Tooltip } from '@chakra-ui/react';
import { Link } from '@remix-run/react';
import { IconType } from 'react-icons';

type Props = {
  href: string;
  icon: IconType;
  label: string;
  count?: number;
  isActive?: boolean;
  isIconOnly: boolean;
  onClick?: () => void;
};

const NavigationContentItem = ({
  href,
  icon,
  label,
  count,
  isIconOnly,
  isActive,
  onClick,
}: Props) => {
  return (
    <Tooltip
      label={label}
      isDisabled={!isIconOnly}
      placement='right'
      bg='neutral.200'
      color='neutral.900'
      hasArrow
      borderRadius='md'
    >
      <Link to={href} rel='viewport' replace onClick={() => onClick && onClick()} prefetch='render'>
        <Flex
          align='center'
          p='3'
          borderRadius='lg'
          cursor='pointer'
          _hover={{
            bg: 'neutral.50',
          }}
          bg={isActive ? 'neutral.50' : 'transparent'}
          h={12}
          w={isIconOnly ? 12 : 'auto'}
        >
          <Icon fontSize='16' as={icon} color={isActive ? 'primary.500' : undefined} />
          <Box position='relative'>
            {count !== undefined && count > 0 && isIconOnly && (
              <Box
                position='absolute'
                top='-12px'
                left='36%'
                bg='primary.500'
                borderRadius='50%'
                color='white'
                fontSize='0.6em'
                w='16px'
                h='16px'
                display='flex'
                alignItems='center'
                justifyContent='center'
                pointerEvents='none'
              >
                {count}
              </Box>
            )}
          </Box>
          {!isIconOnly && (
            <Flex justifyContent='space-between' w='100%' overflow='hidden' ml={4}>
              <Text style={{ whiteSpace: 'nowrap' }} fontWeight={isActive ? 'semibold' : 'normal'}>
                {label}
              </Text>
              {count !== undefined && count > 0 && (
                <Box
                  bg='primary.500'
                  color='white'
                  px={2}
                  borderRadius={5}
                  fontSize='0.8em'
                  display='flex'
                  alignItems='center'
                  justifyContent='center'
                  pointerEvents='none'
                >
                  {count}
                </Box>
              )}
            </Flex>
          )}
        </Flex>
      </Link>
    </Tooltip>
  );
};

export default NavigationContentItem;
