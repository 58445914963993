import { SuspenseWithSpinner } from '@/common/components/SuspenseWithSpinner';
import { Flex, IconButton } from '@chakra-ui/react';
import { MdArrowForwardIos } from 'react-icons/md';
import { useLocalStorage } from 'usehooks-ts';
import NavigationContent from '../NavigationContent';

const SidebarNavigation = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useLocalStorage<boolean>('is-sidebar-open', true);
  return (
    <Flex
      bg='neutral.100'
      borderRight='1px'
      borderRightColor='neutral.300'
      w={{ base: isSidebarOpen === false ? 16 : 52 }}
      overflowX='hidden'
      top='40px'
      position='sticky'
      transition='width 0.3s ease-in-out'
      h='calc(100vh - 40px)'
      direction='column'
    >
      <Flex h='full' justifyContent='space-between' direction='column'>
        <SuspenseWithSpinner>
          <NavigationContent isIconOnly={!isSidebarOpen} />
        </SuspenseWithSpinner>
        <Flex
          bg='neutral.100'
          borderTop='1px solid'
          borderColor='neutral.300'
          position='sticky'
          bottom='0'
          justifyContent='end'
        >
          <IconButton
            size='lg'
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            icon={<MdArrowForwardIos />}
            bg='transparent'
            aria-label='clear-date'
            rounded='full'
            transform={isSidebarOpen ? 'rotate(180deg)' : 'rotate(0deg)'}
            transition='transform 0.3s ease-in-out'
            _hover={{
              bg: 'neutral.50',
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default SidebarNavigation;
