import { useNotificationContext } from '@/context/NotificationContext';
import { BottomNavigationTypeEnum } from '@/graphql/types';
import { ICompanySettingBottomNavigationItem } from '@/modules/companies';
import { normalizeUrlPath } from '@/utils/atoms/url-params';
import useTranslation from '@/utils/i18n/useTranslation';
import { gql } from '@apollo/client';
import { Flex } from '@chakra-ui/react';
import { useLocation } from '@remix-run/react';
import { FaBars } from 'react-icons/fa';
import { LinkItems } from '../../../const';
import { LinkItemType } from '../../../type';
import { useBottomNavigationSuspenseQuery } from './index.generated';
import BottomNavigationItem from './internal/BottomNavigationItem';

export type BottomNavigationProps = {
  onClickOther?: () => void;
};

gql`
fragment BottomNavigation_CompanySetting on CompanySetting {
    accessSchedule
    accessSuggest
    accessAiVoice
    accessCheckList
    accessRequest
    accessProduct
    accessGroup
    accessReport
    bottomNavigationItems {
      type
    }
}
`;

gql`
  query BottomNavigation {
    companySetting {
      ...BottomNavigation_CompanySetting
    }
  }
`;

const BottomNavigation = (props: BottomNavigationProps) => {
  const { onClickOther } = props;
  const { t } = useTranslation();
  const {
    data: {
      companySetting: {
        accessCheckList,
        accessRequest,
        accessSchedule,
        accessProduct,
        accessGroup,
        accessAiVoice,
        bottomNavigationItems = [],
      },
    },
  } = useBottomNavigationSuspenseQuery();
  const { newRequestCount, newChatMessageCount } = useNotificationContext();
  const location = useLocation();
  const normalizedPath = normalizeUrlPath(location.pathname);

  // TODO: bottomNavigationItemsでアクセス権限をサーバーで判定する
  const menuItems: LinkItemType[] = bottomNavigationItems
    .filter(({ type }) => {
      switch (type) {
        case BottomNavigationTypeEnum.Schedule:
          return accessSchedule;
        case BottomNavigationTypeEnum.CheckList:
          return accessCheckList;
        case BottomNavigationTypeEnum.Request:
          return accessRequest;
        case BottomNavigationTypeEnum.Product:
          return accessProduct;
        case BottomNavigationTypeEnum.Group:
          return accessGroup;
        case BottomNavigationTypeEnum.Bot:
          return accessAiVoice;
        default:
          return true;
      }
    })
    .map(({ type }: ICompanySettingBottomNavigationItem) => {
      const menuItem = (LinkItems as Record<string, LinkItemType>)[type];
      if (type === BottomNavigationTypeEnum.Request) {
        return { ...menuItem, count: newRequestCount };
      } else if (type === BottomNavigationTypeEnum.Chat) {
        return { ...menuItem, count: newChatMessageCount };
      } else {
        return menuItem;
      }
    })
    .filter((item) => item !== undefined);

  return (
    <Flex
      as='nav'
      width='100%'
      bg='white'
      justifyContent='space-evenly'
      alignItems='center'
      position='sticky'
      bottom={0}
      paddingTop={2}
      paddingBottom={4}
      borderTop='1px solid #ccc'
      zIndex='10'
    >
      {menuItems.map((item) => (
        <BottomNavigationItem
          key={item.name}
          href={item.link}
          icon={item.icon}
          label={t(`pages.${item.name}`)}
          isActive={normalizedPath === item.link}
          count={item.count}
        />
      ))}

      {onClickOther && (
        <BottomNavigationItem
          icon={FaBars}
          isActive={false}
          label={t('pages.menu')}
          count={newRequestCount + newChatMessageCount}
          onClick={onClickOther}
        />
      )}
    </Flex>
  );
};

export default BottomNavigation;
