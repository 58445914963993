import { useApplicationContext } from '@/context/ApplicationContext';
import { isTruthy } from '@/utils/array/array';
import { useScreenInfos } from '@/utils/mobiles/useScreenInfos';
import { gql } from '@apollo/client';
import { useMemo } from 'react';
import { useUseNavigationItemsHooksSuspenseQuery } from './Navigation.hooks.generated';
import { LinkItems } from './const';
import { LinkItemType } from './type';

gql`
fragment useNavigationItemsHooks_CompanySetting on CompanySetting {
    accessSchedule
    accessSuggest
    accessCheckList
    accessRequest
    accessProduct
    accessGroup
    accessReport
}
`;

gql`
  query useNavigationItemsHooks {
    companySetting {
      ...useNavigationItemsHooks_CompanySetting
    }
  }
`;

const useNavigationItemsHooks = () => {
  const { isAdmin } = useApplicationContext();
  const { isDesktop } = useScreenInfos();
  const {
    data: { companySetting },
  } = useUseNavigationItemsHooksSuspenseQuery();

  const linkItems: LinkItemType[] = useMemo(() => {
    return [
      LinkItems.task,
      companySetting.accessRequest && LinkItems.request,
      companySetting.accessCheckList && LinkItems.checkList,
      companySetting.accessSchedule && LinkItems.schedule,
      companySetting.accessReport && LinkItems.report,
      companySetting.accessReport && LinkItems.approval,
      LinkItems.chat,
      LinkItems.dashboard,
      LinkItems.asset,
      LinkItems.part,
      companySetting.accessProduct && LinkItems.product,
      companySetting.accessSuggest && LinkItems.bot,
      isAdmin && LinkItems.user,
      isAdmin && companySetting.accessGroup && isDesktop && LinkItems.group,
    ].filter(isTruthy);
  }, [isAdmin, isDesktop, companySetting]);

  return linkItems;
};

export default useNavigationItemsHooks;
