import { Box, IconButton, IconButtonProps, forwardRef } from '@chakra-ui/react';
import { MdNotifications } from 'react-icons/md';

type NotificationButtonProps = IconButtonProps & {
  count: number;
};

const NotificationButton = forwardRef<NotificationButtonProps, 'button'>(
  ({ count, ...buttonProps }, ref) => {
    const countDigit = count.toString().length;
    const rightPosition = count > 99 ? '-4px' : countDigit === 2 ? 0 : 1;

    return (
      <IconButton
        {...buttonProps}
        ref={ref}
        fontSize='1.5em'
        isRound={true}
        _hover={{ bg: 'neutral.300' }}
        icon={
          <>
            <MdNotifications />
            {count > 0 && (
              <Box
                as='span'
                color='white'
                fontSize='xs'
                lineHeight='1rem'
                bgColor='red'
                position='absolute'
                px={1}
                h='1rem'
                borderRadius='9999px'
                top={1}
                right={rightPosition}
                zIndex={100}
              >
                {count > 99 ? '+99' : count}
              </Box>
            )}
          </>
        }
      />
    );
  }
);

export default NotificationButton;
