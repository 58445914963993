import { useScreenInfos } from '@/utils/mobiles/useScreenInfos';
import { Box, Flex } from '@chakra-ui/react';
import { ReactNode } from 'react';
import MobileNavigation from './internal/MobileNavigation';
import SidebarNavigation from './internal/SidebarNavigation';
import Topbar from './internal/Topbar';

export const BottomBarHeight = '64px';

export default function Navigation({
  children,
}: {
  children: ReactNode;
}) {
  const { isMobile, isDesktop } = useScreenInfos();
  return (
    <Flex direction='column' minH='100vh'>
      <Topbar />
      <Flex flex={1} minH={0}>
        {isDesktop && <SidebarNavigation />}
        <Box bg='neutral.0' flex={1} minW={0}>
          {children}
        </Box>
      </Flex>
      {isMobile && <MobileNavigation />}
    </Flex>
  );
}
