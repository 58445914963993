import * as Types from '../../../../../../../../graphql/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BottomNavigation_CompanySettingFragment = (
  { __typename?: 'CompanySetting' }
  & Pick<Types.CompanySetting, 'accessSchedule' | 'accessSuggest' | 'accessAiVoice' | 'accessCheckList' | 'accessRequest' | 'accessProduct' | 'accessGroup' | 'accessReport'>
  & { bottomNavigationItems: Array<(
    { __typename?: 'CompanySettingBottomNavigationItem' }
    & Pick<Types.CompanySettingBottomNavigationItem, 'type'>
  )> }
);

export type BottomNavigationQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type BottomNavigationQuery = (
  { __typename?: 'Query' }
  & { companySetting: (
    { __typename?: 'CompanySetting' }
    & Pick<Types.CompanySetting, 'accessSchedule' | 'accessSuggest' | 'accessAiVoice' | 'accessCheckList' | 'accessRequest' | 'accessProduct' | 'accessGroup' | 'accessReport'>
    & { bottomNavigationItems: Array<(
      { __typename?: 'CompanySettingBottomNavigationItem' }
      & Pick<Types.CompanySettingBottomNavigationItem, 'type'>
    )> }
  ) }
);

export const BottomNavigation_CompanySettingFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"BottomNavigation_CompanySetting"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"CompanySetting"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"accessSchedule"}},{"kind":"Field","name":{"kind":"Name","value":"accessSuggest"}},{"kind":"Field","name":{"kind":"Name","value":"accessAiVoice"}},{"kind":"Field","name":{"kind":"Name","value":"accessCheckList"}},{"kind":"Field","name":{"kind":"Name","value":"accessRequest"}},{"kind":"Field","name":{"kind":"Name","value":"accessProduct"}},{"kind":"Field","name":{"kind":"Name","value":"accessGroup"}},{"kind":"Field","name":{"kind":"Name","value":"accessReport"}},{"kind":"Field","name":{"kind":"Name","value":"bottomNavigationItems"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"type"}}]}}]}}]} as unknown as DocumentNode;
export const BottomNavigationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"BottomNavigation"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"companySetting"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"BottomNavigation_CompanySetting"}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"BottomNavigation_CompanySetting"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"CompanySetting"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"accessSchedule"}},{"kind":"Field","name":{"kind":"Name","value":"accessSuggest"}},{"kind":"Field","name":{"kind":"Name","value":"accessAiVoice"}},{"kind":"Field","name":{"kind":"Name","value":"accessCheckList"}},{"kind":"Field","name":{"kind":"Name","value":"accessRequest"}},{"kind":"Field","name":{"kind":"Name","value":"accessProduct"}},{"kind":"Field","name":{"kind":"Name","value":"accessGroup"}},{"kind":"Field","name":{"kind":"Name","value":"accessReport"}},{"kind":"Field","name":{"kind":"Name","value":"bottomNavigationItems"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"type"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useBottomNavigationQuery__
 *
 * To run a query within a React component, call `useBottomNavigationQuery` and pass it any options that fit your needs.
 * When your component renders, `useBottomNavigationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBottomNavigationQuery({
 *   variables: {
 *   },
 * });
 */
export function useBottomNavigationQuery(baseOptions?: Apollo.QueryHookOptions<BottomNavigationQuery, BottomNavigationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BottomNavigationQuery, BottomNavigationQueryVariables>(BottomNavigationDocument, options);
      }
export function useBottomNavigationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BottomNavigationQuery, BottomNavigationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BottomNavigationQuery, BottomNavigationQueryVariables>(BottomNavigationDocument, options);
        }
export function useBottomNavigationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BottomNavigationQuery, BottomNavigationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BottomNavigationQuery, BottomNavigationQueryVariables>(BottomNavigationDocument, options);
        }
export type BottomNavigationQueryHookResult = ReturnType<typeof useBottomNavigationQuery>;
export type BottomNavigationLazyQueryHookResult = ReturnType<typeof useBottomNavigationLazyQuery>;
export type BottomNavigationSuspenseQueryHookResult = ReturnType<typeof useBottomNavigationSuspenseQuery>;
export type BottomNavigationQueryResult = Apollo.QueryResult<BottomNavigationQuery, BottomNavigationQueryVariables>;