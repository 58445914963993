import * as Types from '../../../../../../graphql/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type NavigationContentQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type NavigationContentQuery = (
  { __typename?: 'Query' }
  & Pick<Types.Query, 'countMyPendingWorkflowExecutions'>
);


export const NavigationContentDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"NavigationContent"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"countMyPendingWorkflowExecutions"}}]}}]} as unknown as DocumentNode;

/**
 * __useNavigationContentQuery__
 *
 * To run a query within a React component, call `useNavigationContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useNavigationContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNavigationContentQuery({
 *   variables: {
 *   },
 * });
 */
export function useNavigationContentQuery(baseOptions?: Apollo.QueryHookOptions<NavigationContentQuery, NavigationContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NavigationContentQuery, NavigationContentQueryVariables>(NavigationContentDocument, options);
      }
export function useNavigationContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NavigationContentQuery, NavigationContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NavigationContentQuery, NavigationContentQueryVariables>(NavigationContentDocument, options);
        }
export function useNavigationContentSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<NavigationContentQuery, NavigationContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<NavigationContentQuery, NavigationContentQueryVariables>(NavigationContentDocument, options);
        }
export type NavigationContentQueryHookResult = ReturnType<typeof useNavigationContentQuery>;
export type NavigationContentLazyQueryHookResult = ReturnType<typeof useNavigationContentLazyQuery>;
export type NavigationContentSuspenseQueryHookResult = ReturnType<typeof useNavigationContentSuspenseQuery>;
export type NavigationContentQueryResult = Apollo.QueryResult<NavigationContentQuery, NavigationContentQueryVariables>;