import { useNotificationContext } from '@/context/NotificationContext';
import { gql } from '@apollo/client';
import { Flex } from '@chakra-ui/react';
import { useLocation } from '@remix-run/react';
import { useTranslation } from 'react-i18next';
import useNavigationItemsHooks from '../Navigation.hooks';
import { useNavigationContentQuery } from './index.generated';
import NavigationContentItem from './internal/NavigationContentItem';

gql`
query NavigationContent {
  countMyPendingWorkflowExecutions
}
`;

type Props = {
  isIconOnly?: boolean;
  onItemClick?: () => void;
};

const NavigationContent = ({ isIconOnly = false, onItemClick }: Props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const linkItems = useNavigationItemsHooks();
  const { newRequestCount, newChatMessageCount } = useNotificationContext();
  const { data } = useNavigationContentQuery({
    pollInterval: 60000,
  });
  const countMap = new Map([
    ['request', newRequestCount],
    ['chat', newChatMessageCount],
    ['approval', data?.countMyPendingWorkflowExecutions],
  ]);

  const checkIsActive = (link: string) => {
    if (link === '/') {
      return location.pathname === link;
    }
    return location.pathname.startsWith(link);
  };

  return (
    <Flex overflowY='auto' px={2} gap={1} my={1} flexDirection={'column'} bg='neutral.100'>
      {linkItems.map((link) => (
        <NavigationContentItem
          key={link.name}
          icon={link.icon}
          href={link.link}
          label={t(`pages.${link.name}`)}
          count={countMap.get(link.name)}
          isIconOnly={isIconOnly}
          isActive={checkIsActive(link.link)}
          onClick={() => onItemClick && onItemClick()}
        />
      ))}
    </Flex>
  );
};

export default NavigationContent;
