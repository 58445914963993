import { Box, Flex, Icon, Text } from '@chakra-ui/react';
import { Link } from '@remix-run/react';
import { IconType } from 'react-icons';

type Props = {
  icon: IconType;
  label: string;
  isActive: boolean;
  count?: number;
  href?: string;
  onClick?: () => void;
};

const BottomNavigationItem = ({ icon, label, isActive, onClick, href, count }: Props) => {
  const Item = (
    <Flex
      flexDirection={'column'}
      color={isActive ? 'primary.500' : 'gray.500'}
      justifyContent='center'
      alignItems='center'
      position='relative'
      minWidth='40px'
    >
      <Icon as={icon} boxSize={5} />
      <Text
        fontSize='2xs'
        mt={1}
        whiteSpace='nowrap'
        overflow='hidden'
        textOverflow='ellipsis'
        maxWidth='100%'
      >
        {label}
      </Text>
      {count !== undefined && count > 0 && (
        <Box
          position='absolute'
          top='-5px'
          left='calc(50% + 10px)'
          bg='primary.500'
          borderRadius='50%'
          color='white'
          fontSize='0.7em'
          w='20px'
          h='20px'
          display='flex'
          alignItems='center'
          justifyContent='center'
          pointerEvents='none'
        >
          {count}
        </Box>
      )}
    </Flex>
  );
  return href ? (
    <Link to={href} rel='viewport' replace onClick={onClick} prefetch='render'>
      {Item}
    </Link>
  ) : (
    <Box onClick={onClick}>{Item}</Box>
  );
};

export default BottomNavigationItem;
