import * as Types from '../../../../../graphql/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UseNavigationItemsHooks_CompanySettingFragment = (
  { __typename?: 'CompanySetting' }
  & Pick<Types.CompanySetting, 'accessSchedule' | 'accessSuggest' | 'accessCheckList' | 'accessRequest' | 'accessProduct' | 'accessGroup' | 'accessReport'>
);

export type UseNavigationItemsHooksQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UseNavigationItemsHooksQuery = (
  { __typename?: 'Query' }
  & { companySetting: (
    { __typename?: 'CompanySetting' }
    & Pick<Types.CompanySetting, 'accessSchedule' | 'accessSuggest' | 'accessCheckList' | 'accessRequest' | 'accessProduct' | 'accessGroup' | 'accessReport'>
  ) }
);

export const UseNavigationItemsHooks_CompanySettingFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"useNavigationItemsHooks_CompanySetting"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"CompanySetting"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"accessSchedule"}},{"kind":"Field","name":{"kind":"Name","value":"accessSuggest"}},{"kind":"Field","name":{"kind":"Name","value":"accessCheckList"}},{"kind":"Field","name":{"kind":"Name","value":"accessRequest"}},{"kind":"Field","name":{"kind":"Name","value":"accessProduct"}},{"kind":"Field","name":{"kind":"Name","value":"accessGroup"}},{"kind":"Field","name":{"kind":"Name","value":"accessReport"}}]}}]} as unknown as DocumentNode;
export const UseNavigationItemsHooksDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"useNavigationItemsHooks"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"companySetting"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"useNavigationItemsHooks_CompanySetting"}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"useNavigationItemsHooks_CompanySetting"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"CompanySetting"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"accessSchedule"}},{"kind":"Field","name":{"kind":"Name","value":"accessSuggest"}},{"kind":"Field","name":{"kind":"Name","value":"accessCheckList"}},{"kind":"Field","name":{"kind":"Name","value":"accessRequest"}},{"kind":"Field","name":{"kind":"Name","value":"accessProduct"}},{"kind":"Field","name":{"kind":"Name","value":"accessGroup"}},{"kind":"Field","name":{"kind":"Name","value":"accessReport"}}]}}]} as unknown as DocumentNode;

/**
 * __useUseNavigationItemsHooksQuery__
 *
 * To run a query within a React component, call `useUseNavigationItemsHooksQuery` and pass it any options that fit your needs.
 * When your component renders, `useUseNavigationItemsHooksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUseNavigationItemsHooksQuery({
 *   variables: {
 *   },
 * });
 */
export function useUseNavigationItemsHooksQuery(baseOptions?: Apollo.QueryHookOptions<UseNavigationItemsHooksQuery, UseNavigationItemsHooksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UseNavigationItemsHooksQuery, UseNavigationItemsHooksQueryVariables>(UseNavigationItemsHooksDocument, options);
      }
export function useUseNavigationItemsHooksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UseNavigationItemsHooksQuery, UseNavigationItemsHooksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UseNavigationItemsHooksQuery, UseNavigationItemsHooksQueryVariables>(UseNavigationItemsHooksDocument, options);
        }
export function useUseNavigationItemsHooksSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UseNavigationItemsHooksQuery, UseNavigationItemsHooksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UseNavigationItemsHooksQuery, UseNavigationItemsHooksQueryVariables>(UseNavigationItemsHooksDocument, options);
        }
export type UseNavigationItemsHooksQueryHookResult = ReturnType<typeof useUseNavigationItemsHooksQuery>;
export type UseNavigationItemsHooksLazyQueryHookResult = ReturnType<typeof useUseNavigationItemsHooksLazyQuery>;
export type UseNavigationItemsHooksSuspenseQueryHookResult = ReturnType<typeof useUseNavigationItemsHooksSuspenseQuery>;
export type UseNavigationItemsHooksQueryResult = Apollo.QueryResult<UseNavigationItemsHooksQuery, UseNavigationItemsHooksQueryVariables>;